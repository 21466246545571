.deviceBackground {
  margin: 10px;
  height: 70vh;
}
.cusDeviceCard {
  width: 300px;
  margin-top: 30px;
  margin-right: 20px;
  .inner {
    margin-top: 10px;
    font-size: 16px;
  }
}
.icon {
  font-size: 25px;
}
.cusAddressCard {
  min-width: 250px;
  margin-top: 30px;
  margin-right: 20px;
}
.cusAddLocation {
  text-align: center;
  width: 250px;
  height: 190px;
  margin-top: 30px;
  margin-right: 20px;
}
.inner {
  margin-top: 20px;
}
.innerTitle {
  font-size: 18px;
  margin-left: 10px;
}
.normal {
  color: green;
}
.abnormal {
  color: red;
}
.cusEditTitle1 {
  margin-top: 30px;
  padding-bottom: 30px;
}
.cusEditTitle2 {
  margin-top: 50px;
  padding-bottom: 30px;
}
.cusEditTitle3 {
  margin-top: 30px;
  margin-bottom: 30px;
}
.marginBottom {
  padding-bottom: 15px;
}
.remind {
  margin-top: -25px;
}
.select {
  width: 30%;
}
.h4 {
  padding-top: 30px;
}
.cusEditCard {
  width: 250px;
  margin-top: 20px;
  margin-right: 20px;
}
.marginTop {
  padding-top: 10px;
}

.headerInfo {
  margin-left: 20px;
  margin-top: 10px;
}
.headerInfoText {
  font-size: 20px;
  font-weight: bold;
}
.steps_content {
  min-height: 200px;
  padding-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 70px;
}
.noteMarginTop {
  margin-top: 30px;
}

.box {
  // 点击某一行的样式
  .rowBgColor {
    background-color: #e6f7ff;
  }

  // 去除鼠标经过默认的背景颜色
  .ant-table-row:hover {
    background: none !important;
  }
}
.billTag {
  font-size: 14px;
}
