.newIcon {
    font-size: 20px;
}
.oldIcon {
    font-size: 20px;
    color: "gray";
}
.noticeIcon {
    color: #1890ff;
}
@keyframes shake {
    0% {
        transform: translateX(0);
    }
    20% {
        transform: translateX(-10px);
    }
    40% {
        transform: translateX(10px);
    }
    60% {
        transform: translateX(-10px);
    }
    80% {
        transform: translateX(10px);
    }
    100% {
        transform: translateX(0);
    }
}

.shakeAnimation {
    animation: shake 1s;
}
