.historyCard {
  margin: 10px;
  height: 600px;
}
.text {
  font-size: 18px;
}
.mapPosition {
  margin-top: -15px;
  margin-bottom: -15px;
}
.map {
  max-width: 100%;
  min-height: 500px;
}
.dataPicker {
  width: 40%;
  margin-right: 5px;
}
.addressSelect {
  width: 60%;
  margin-right: 5px;
}
.gasPriceNew {
  height: 100%;
  width: 100%;
}
.tablePosition {
  margin-top: 30px;
}
.pageHeader {
  margin-top: 20px;
}

.tab {
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: -40px;
}

.orderInfo {
  margin-top: 20px;
}
.secondTitle {
  color: #757575;
}
.secondTitle2 {
  margin-top: 20px;
  color: #757575;
}
.fullSelect {
  width: 100%;
}
.priceText {
  font-size: 20px;
}
.orderBtn {
  margin-top: 30px;
}
.stepsIcon {
  font-size: 30px;
}
