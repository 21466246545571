.tablePosition {
  margin-top: 30px;
}
.cardPosition {
  margin: 10px;
  max-height: 100%;
}
.cardTitle {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.cardInfoPosition {
  margin-left: 20px;
}
.cardInfoTitle {
  color: #757575;
}
.noteCard {
  width: 30%;
  height: 60px;
  margin-top: 20px;
  border-color: #d9d9d9;
}
.cusNoteCard {
  width: 60vh;
  height: 15vh;
  margin-top: 20px;
  border-color: #d9d9d9;
}
.haveContent {
  margin: -5px;
}
.NoContent {
  color: #757575;
  margin: -5px;
}
.cardInner {
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.Input {
  width: 20%;
  margin-top: 20px;
}
.Title {
  margin-top: 30px;
  margin-left: 20px;
}
.headerButton {
  margin-right: 20;
}
.tableEditButton {
  color: #faad14;
}
.tableDelButton {
  color: #f5222d;
}
.tableSureButton {
  color: #03b100;
}
.tableRow {
  position: absolute;
  top: 100%;
}
.newAddressBtn {
  width: 100%;
  margin-top: 20px;
}
.headerTitle {
  margin-top: 5px;
  margin-left: 5px;
}
.steps_content {
  min-height: 200px;
  padding-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 70px;
}
.spinDiv {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}
.headerBtn {
  margin-top: 25px;
}
@media print {
  .pageBreak {
    page-break-after: always;
  }
}
.componentToPrint {
  display: none;
}
.customHeader {
  background-color: #f9f9f9;
}
.financeText {
  font-size: 16px;
}
.financeHeight {
  height: 80px;
  font-size: 16px;
}
.financeZero {
  color: #b1b1b1;
}
.onSelectBackground {
  background-color: #bae7ff;
 
}

@media print {
  .pagebreak {
    margin-top: 1rem;
    display: block;
    page-break-inside: avoid;
  }
}
@page {
  size: auto;
  margin: 5mm;
  bottom-left {
    content: counter(page);
  }
}
