body {
  background: rgb(255, 255, 255);
}
.inputForm {
  .header {
    justify-content: center;
    text-align: center;
    .logo {
      width: 10vh;
      height: 10vh;
    }
    .companyName {
      margin: 0;
      margin-top: 3%;
      font-weight: 200;
      color: #096dd9;
    }
    .webName {
      margin: 0;
      color: #096dd9;
    }
  }
  .input {
    display: flex;
    justify-content: center;
    width: 100%;
    .inputBox {
      width: 60%;
    }
    .icon {
      color: #1890ff;
    }
  }
  .submit {
    width: 60%;
    margin-left: 20%;
    .submitSize {
      width: 100%;
      font-weight: 500;
    }
  }
  .forgetPs {
    float: right;
    font-weight: 500;
  }
  .forget_header {
    justify-content: center;
    text-align: center;
    .forget_icon {
      font-size: 5vh;
    }
    .forget_stitle {
      margin: 0;
      margin-top: 3%;
      font-weight: 300;
      color: #096dd9;
    }
    .forget_title {
      margin: 0;
      margin-top: 3%;
      color: #096dd9;
    }
  }
  .forget_input {
    justify-content: center;
    width: 100%;
    .forget_icon {
      color: #1890ff;
    }
  }
}

.photo {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

@media (max-width: 960px) {
  .inputForm {
    height: 100vh;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    background-image: url(../../assets/LoginBg.jpg);
  }
  .box {
    margin-top: 25vh;
    margin-bottom: 25vh;
    margin-left: 10%;
    width: 80%;
    background-color: rgba(255, 255, 255, 0.66);
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
    line-height: 1;
    height: 50%;
    padding: 3%;
  }
}
