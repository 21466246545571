@import url("../node_modules/leaflet/dist/leaflet.css");

.body {
  margin: 0;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}



.ant-space-item{
  font-size: 16px;
}

text{
  font-size: 16px;
}

.ant-descriptions-item-content {
  font-size: 16px;
}

.ant-btn-sm {
    height: 28px;
}

.ant-tag {
    height: 28px;
    align-content: center;
}